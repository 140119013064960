import {useEffect, useState} from "react";


export const useValidation = (value, validators) => {
    useEffect(() => {
        for (const validator of validators) {
            switch (validator.name) {
                case 'maxLength':
                    value.length > validator.restrictionValue ? validator.value = false : validator.value = true
                    break
                case 'minLength':
                    value.length < validator.restrictionValue ? validator.value = false : validator.value = true
                    break
                case 'isEmpty':
                    value ? validator.value = true : validator.value = false
                    break
                case 'isEmail':
                    String(value)
                        .toLowerCase()
                        .match(
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        ) ? validator.value = true : validator.value = false
                    break
                case 'isStrongPassword':
                    String(value)
                        .match(
                            /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/
                        )
                    ? validator.value = true : validator.value = false
                    break
                case 'isMobilePhoneValidator':
                    String(value)
                        .match(
                            /(?:\+|\d)[\d\-\(\) ]{9,}\d/g
                        )
                        ? validator.value = true : validator.value = false
                    break
                default:
                    console.warn("Invalid validator")
            }
        }
    }, [value])
}

export const useInput = (initialValue = "") => {
    const [value, setValue] = useState(initialValue)
    const [isDirty, setDirty] = useState(false)

    const onChange = (e) => {
        setValue(e.target.value)
    }

    const onFocus = () => {
        setDirty(false)
    }

    const onBlur = () => {
        setDirty(true)
    }

    return {
        value,
        onChange,
        onBlur,
        onFocus,
        isDirty,
        setDirty
    }
}