import styles from "./styles.module.css"
import {ContactForm} from "../ContactForm/ContactForm";
import {BackButton, MainButton} from "@vkruglikov/react-telegram-web-app";
import {useMemo, useState} from "react";
import {useInput} from "../../utils/hooks/input";
import {isEmptyValidator, isMobilePhoneValidator, maxLengthValidator, minLengthValidator} from "../../utils/validators";
import {UnderlinedTextInput} from "../common/Inputs/UnderlinedTextInput/UnderlinedTextInput";

export const ConsultationRequest = () => {
    const [validated, setValidated] = useState(false)

    const inputHooks = {
        name: useInput(),
        phone: useInput(),
        extras: useInput(),
    }

    const validators = useMemo(() => (
        {
            name: [new isEmptyValidator(), new minLengthValidator(3), new maxLengthValidator()],
            phone: [new isMobilePhoneValidator(), new isEmptyValidator()]
        }
    ), [])

    const submitForm = () => {

        const data = {
            type: "consultation",
            form_data: {
                name: inputHooks.name.value,
                phone: inputHooks.phone.value,
                extras: inputHooks.extras.value,
            }
        }

        window.Telegram.WebApp.sendData(JSON.stringify(data));
    }

    return (
        <div className={styles.contents}>
            <h3 className={styles.title}>Введите свои данные</h3>
            <ContactForm validators={validators} setValidated={setValidated}>
                <UnderlinedTextInput label={"Имя"} validators={validators.name} inputHook={inputHooks.name}/>
                <UnderlinedTextInput label={"Телефон"} validators={validators.phone} inputHook={inputHooks.phone}/>
                <UnderlinedTextInput label={"Комментарий"} inputHook={inputHooks.extras}/>
            </ContactForm>
            <MainButton text={"Отправить"} onClick={() => submitForm()} disable={validated}/>
        </div>
    )
}
