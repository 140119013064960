import {
    CATALOG_CATEGORIES_LOADING,
    CATALOG_CATEGORIES_LOADING_SUCCESS,
    CATALOG_CATEGORIES_LOADING_FAIL,
    CATALOG_CATEGORY_CHANGED
} from "../../../actions/types/Catalog/Categories/types";

const initialState = {
    loading: false,
    errors: [],
    items: []
}

export const catalogCategoriesReducer = (state = initialState, action) => {
    switch (action.type) {
        case CATALOG_CATEGORIES_LOADING:
            return {
                ...state,
                loading: true
            }
        case CATALOG_CATEGORIES_LOADING_SUCCESS:
            return {
                ...state,
                loading: false,
                errors: [],
                items: action.payload
            }
        case CATALOG_CATEGORIES_LOADING_FAIL:
            return {
                ...state,
                loading: false,
                errors: action.payload
            }
        case CATALOG_CATEGORY_CHANGED:
            return {
                ...state,
                active_category: action.payload
            }
        default:
            return state;
    }
}
