import {CART_LOADING, CART_LOADING_FAIL, CART_LOADING_SUCCESS} from "../types/Cart/types";
import {api} from "../../utils/api";

export const loadCartItems = (items) => dispatch => {
    dispatch({
        type: CART_LOADING
    })
    const ids = items.map(item => item.id).join()

    api
        .get(`catalog/products?ids=${ids}`)
        .then(response => {
            dispatch({
                type: CART_LOADING_SUCCESS,
                payload: response.data
            })
        })
        .catch(error => {
            dispatch({
                type: CART_LOADING_FAIL,
                payload: error.response.data
            })
        })
}