import {PRODUCT_LOADING, PRODUCT_LOADING_FAIL, PRODUCT_LOADING_SUCCESS} from "../../actions/types/Product/types";

const initialState = {
    loading: false,
    errors: [],
    data: null
}

export const productReducer = (state = initialState, action) => {
    switch (action.type) {
        case PRODUCT_LOADING:
            return {
                ...state,
                loading: false,
                data: null
            }
        case PRODUCT_LOADING_SUCCESS:
            return {
                ...state,
                loading: false,
                errors: [],
                data: action.payload[0]
            }
        case PRODUCT_LOADING_FAIL:
            return {
                ...state,
                loading: false,
                errors: action.payload
            }
        default:
            return state
    }
}
