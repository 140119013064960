import styles from "./styles.module.css"
import {CartItem} from "../CartItem/CartItem";

export const CartItemsList = ({items, addItem, updateItemQuantity, removeItem, isEmpty}) => {
    if (isEmpty) {
        return (
            <div className={styles.emptyContent}>
                <h2 className={styles.title}>
                    Вы не добавили ни одной позиции в корзину :(
                </h2>
            </div>
        )
    }

    return (
        <div className={styles.contents}>
            {
                items.map((obj, idx) => {
                    return <CartItem {...obj} key={idx} addToCart={() => addItem(obj, 1)} removeFromCart={() => updateItemQuantity(obj.id, obj.quantity - 1)}
                                     removeEntireItem={() => removeItem(obj.id)}/>
                })
            }
        </div>
    )
}