import styles from "./styles.module.css"
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {loadCatalogCategories} from "../../actions/Catalog/Categories/actions";
import {CategoryCard} from "../CategoryCard/CategoryCard";

export const CategoriesList = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(loadCatalogCategories())
    }, [dispatch])

    const data = useSelector(state => state.catalog.categories)

    return (
        <div className={styles.contents}>
            {
                data.items.map((obj, idx) => (
                    <CategoryCard {...obj} key={idx} active={data.active_category === obj.id}/>
                ))
            }
        </div>
    )
}
