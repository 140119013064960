import React, {useId, useRef} from 'react';

import {useValidation} from "../../../../utils/hooks/input";

export const BaseInput = ({
                              placeholder = "Введите...",
                              type,
                              disabled = false,
                              className = "",
                              id,
                              validators = [],
                              inputHook
                          }) => {

    useValidation(inputHook.value, validators)

    return (
        <input onSubmit={() => inputHook.setDirty(true)} autoComplete="new-password" value={inputHook.value}
               onChange={inputHook.onChange} className={className} placeholder={placeholder} id={id} type={type}
               disabled={disabled} onBlur={inputHook.onBlur} onFocus={inputHook.onFocus}/>
    );
};
