import styles from "./styles.module.css"

import {ProductsList} from "../ProductsList/ProductsList";
import {MainButton, BackButton} from "@vkruglikov/react-telegram-web-app";
import {useNavigate} from "react-router-dom";
import {CategoriesList} from "../CategoriesList/CategoriesList";

export const Catalog = () => {
    const navigate = useNavigate()

    return (
        <div className={styles.contents}>
            <CategoriesList/>
            <ProductsList/>
            <MainButton text={"Корзина"} onClick={() => navigate('cart')}/>
        </div>
    )
}
