export class isEmptyValidator {
    constructor() {
        this.name = "isEmpty"
        this.value = false
        this.errorLabel = "Поле не может быть пустым"
    }
}

export class minLengthValidator {
    constructor(length = 8) {
        this.name = "minLength"
        this.value = false
        this.restrictionValue = length
        this.errorLabel = `Минимальная длина - ${length} символов`
    }
}

export class maxLengthValidator {
    constructor(length = 25) {
        this.name = "maxLength"
        this.value = false
        this.restrictionValue = length
        this.errorLabel = `Максимальная длина - ${length} символов`
    }
}

export class isEmailValidator {
    constructor() {
        this.name = "isEmail"
        this.value = false
        this.errorLabel = `Укажите верный адрес почты`
    }
}

export class isStrongPasswordValidator {
    constructor() {
        this.name = "isStrongPassword"
        this.value = false
        this.errorLabel = `Данный пароль слишком простой`
    }
}

export class isMobilePhoneValidator {
    constructor() {
        this.name = "isMobilePhoneValidator"
        this.value = false
        this.errorLabel = `Укажите верный номер телефона`
    }
}
