import styles from "./styles.module.css"
import {useDispatch} from "react-redux";
import {setActiveCategory} from "../../actions/Catalog/Categories/actions";

export const CategoryCard = ({id, name, logo, logo_alt = name, active}) => {
    const dispatch = useDispatch()

    const setActive = () => {
        if (active)
            dispatch(setActiveCategory(undefined))
        else
            dispatch(setActiveCategory(id))
    }

    return (
        <div className={`${styles.card} ${active ? styles.active : ""}`} onClick={setActive}>
            <img src={logo} alt={logo_alt}/>
            <p>{name}</p>
        </div>
    )
}
