import styles from './styles.module.css';

import {BaseInput} from "../BaseInput/BaseInput";
import React, {useEffect, useId, useState} from "react";

export const UnderlinedTextInput = ({
                                               label,
                                               placeholder = "Введите...",
                                               type = "text",
                                               disabled = false,
                                               validators,
                                               inputHook
                                           }) => {

    const id = useId()

    const [validationClass, setValidationClass] = useState("")

    useEffect(() => {
        if(!inputHook.isDirty || !validators || !inputHook.value)
            setValidationClass("")
        else if(validators.some(validator => !validator.value))
            setValidationClass(styles.error)
        else
            setValidationClass("")
    }, [inputHook.value, inputHook.isDirty])

    return (
        <div className={styles.contents}>
            <label className={`${styles.label} ${validationClass}`} htmlFor={id}>{label}</label>
            <BaseInput placeholder={placeholder} type={type} disabled={disabled} validators={validators}
                       inputHook={inputHook} className={`${styles.input} ${validationClass}`} id={id}/>
            {/*{*/}
            {/*    validators && <div className={styles.errors}>*/}
            {/*        {*/}
            {/*            validators.map((validator, idx) => (*/}
            {/*                !validator.value && inputHook.isDirty && <p className={styles.error} key={idx}>*/}
            {/*                    {validator.errorLabel}*/}
            {/*                </p>*/}
            {/*            ))*/}
            {/*        }*/}
            {/*    </div>*/}
            {/*}*/}
        </div>
    );
};
