import styles from "./styles.module.css"
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {loadCatalogProducts} from "../../actions/Catalog/Products/actions";
import {ProductCard} from "../ProductCard/ProductCard";

import {ClipLoader} from "react-spinners";
import {useCart} from "react-use-cart";

export const ProductsList = ({category = undefined}) => {
    const {
        isEmpty,
        totalUniqueItems,
        items,
        updateItemQuantity,
        addItem,
        removeItem,
        getItem
    } = useCart();

    const dispatch = useDispatch()

    const active_category = useSelector(state => state.catalog.categories.active_category)

    useEffect(() => {
        dispatch(loadCatalogProducts(active_category))
    }, [dispatch, active_category])

    const data = useSelector(state => state.catalog.products)

    if (data.loading) {
        const color = document.documentElement.style.getPropertyValue('--tg-theme-button-color')

        return <div className={styles.loading}><ClipLoader color={color} /></div>
    }

    if (!data.items.length) {
        return (
            <div className={styles.noContent}>
                <h3>Здесь будут товары, но пока здесь пусто :(</h3>
            </div>
        )
    }

    return (
        <div className={styles.contents}>
            {
                data.items.map((obj, idx) => {
                    let quantity = 0;
                    const target = getItem(obj.id)
                    if (target)
                        quantity = target.quantity

                    return (
                        <ProductCard {...obj} key={idx} addToCart={() => addItem(obj, 1)}
                                     removeFromCart={() => updateItemQuantity(obj.id, quantity - 1)}
                                     quantity={quantity}
                                     removeEntireItem={() => removeItem(obj.id)}/>
                    )
                })
            }
        </div>
    )
}