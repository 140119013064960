import styles from "./styles.module.css"

import {ContactForm} from "../ContactForm/ContactForm";
import {BackButton, MainButton} from "@vkruglikov/react-telegram-web-app";
import {useCart} from "react-use-cart";
import axios from "axios";
import {useMemo, useState} from "react";
import {useInput} from "../../utils/hooks/input";
import {isEmptyValidator, isMobilePhoneValidator, maxLengthValidator, minLengthValidator} from "../../utils/validators";
import {UnderlinedTextInput} from "../common/Inputs/UnderlinedTextInput/UnderlinedTextInput";

import {omit} from "lodash";
import {useNavigate} from "react-router-dom";

export const PlaceOrder = () => {
    const navigate = useNavigate()

    const goBack = () => {
        if (window.history.state && window.history.state.idx > 0) {
            navigate(-1);
        }
    }

    const {items, emptyCart, cartTotal} = useCart()

    const [validated, setValidated] = useState(false)

    const inputHooks = {
        name: useInput(),
        phone: useInput(),
        extras: useInput(),
    }

    const validators = useMemo(() => (
        {
            name: [new isEmptyValidator(), new minLengthValidator(3), new maxLengthValidator()],
            phone: [new isMobilePhoneValidator(), new isEmptyValidator()]
        }
    ), [])

    const submitForm = () => {

        const data = {
            type: "order",
            cart: items.map((item) => omit(item, ['image', 'id', 'description', 'price', 'itemTotal', 'category.logo'])),
            cartTotal: cartTotal,
            form_data: {
                name: inputHooks.name.value,
                phone: inputHooks.phone.value,
                extras: inputHooks.extras.value,
            },
        }

        emptyCart()

        window.Telegram.WebApp.sendData(JSON.stringify(data));
    }

    return (
        <div className={styles.contents}>
            <h3 className={styles.title}>Введите свои данные</h3>
            <ContactForm validators={validators} setValidated={setValidated}>
                <UnderlinedTextInput label={"Имя"} validators={validators.name} inputHook={inputHooks.name}/>
                <UnderlinedTextInput label={"Телефон"} validators={validators.phone} inputHook={inputHooks.phone}/>
                <UnderlinedTextInput label={"Комментарий"} inputHook={inputHooks.extras}/>
            </ContactForm>
            <MainButton text={"Связаться с менеджером"} onClick={() => submitForm()} disable={validated}/>
            <BackButton onClick={goBack}/>
        </div>
    )
}
