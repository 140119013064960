import styles from "./styles.module.css"

import {ReactComponent as TrashBinIcon} from "../../resources/media/icons/TrashBinIcon.svg";
import {Link, useNavigate} from "react-router-dom";
import {setActiveCategory} from "../../actions/Catalog/Categories/actions";
import {useDispatch} from "react-redux";

export const CartItem = ({
                             id,
                             name,
                             image,
                             image_alt = name,
                             price,
                             category,
                             quantity,
                             addToCart,
                             removeFromCart,
                             removeEntireItem
                         }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const goToCategory = () => {
        dispatch(setActiveCategory(category.id))
        navigate('/')
    }

    return (
        <div className={styles.card}>
            <div className={styles.container}>
                <img src={image} alt={image_alt}/>
                <div className={styles.info}>
                    <p className={styles.name}>
                        {name}
                    </p>
                    <span onClick={goToCategory} className={styles.link}>{category.name}</span>
                </div>
                <button className={styles.removeItem} onClick={() => removeEntireItem(id)}>
                    <TrashBinIcon/>
                </button>
            </div>
            <div className={styles.actionBar}>
                <div className={styles.actions}>
                    <button onClick={removeFromCart}>-</button>
                    <span className={styles.label}>{quantity}</span>
                    <button onClick={addToCart}>+</button>
                </div>
                <p className={styles.price}>{price * quantity}₽</p>
            </div>
        </div>
    )
}