import {BrowserRouter, Route, Routes, useNavigate} from "react-router-dom";
import LocationProvider from "../LocationProvider/LocationProvider";
import {Provider} from 'react-redux'
import {Catalog} from "../../Catalog/Catalog";
import {Cart} from "../../Cart/Cart";
import {ProductPage} from "../../ProductPage/ProductPage";
import {PlaceOrder} from "../../PlaceOrder/PlaceOrder";
import {ConsultationRequest} from "../../ConsultationRequest/ConsultationRequest";
import {ExchangeRequest} from "../../ExchangeRequest/ExchangeRequest";
import {TechSupportRequest} from "../../TechSupportRequest/TechSupportRequest";

export const Root = ({store}) => {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <LocationProvider>
                    <Routes>
                        <Route index element={<Catalog/>}/>
                        <Route path={"cart"} element={<Cart/>}/>
                        <Route path={"order"} element={<PlaceOrder/>}/>
                        <Route path={"consultation"} element={<ConsultationRequest/>}/>
                        <Route path={"exchange"} element={<ExchangeRequest/>}/>
                        <Route path={"tech_support"} element={<TechSupportRequest/>}/>
                        <Route path={"product/:id"} element={<ProductPage/>}/>
                    </Routes>
                </LocationProvider>
            </BrowserRouter>
        </Provider>
    )
}