import styles from "./styles.module.css"
import {useEffect} from "react";

export const ContactForm = ({validators, setValidated, children}) => {
    useEffect(() => {
        for(const validatorsType in validators) {
            for(const validator of validators[validatorsType]) {
                if (!validator.value) {
                    setValidated(false)
                    return
                }
            }
        }
    }, [JSON.stringify(validators)])

    return (
        <div className={styles.contents}>
            {children}
        </div>
    )
}
