import styles from "./styles.module.css"

import {useNavigate} from "react-router-dom"
import {CartItemsList} from "../CartItemsList/CartItemsList";
import {BackButton, MainButton} from "@vkruglikov/react-telegram-web-app";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {loadCartItems} from "../../actions/Cart/actions";
import {useCart} from "react-use-cart";


export const Cart = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const {
        isEmpty,
        items,
        addItem,
        updateItemQuantity,
        removeItem,
        updateItem,
        cartTotal,
        totalItems
    } = useCart();

    const goBack = () => {
        if (window.history.state && window.history.state.idx > 0) {
            navigate(-1);
        }
    }

    useEffect(() => {
        dispatch(loadCartItems(items))
    }, [dispatch])

    const data = useSelector(state => state.cart)

    useEffect(() => {
        for(const item of data.items) {
            updateItem(item.id, {...item})
        }
    }, [data])

    return (
        <>
            <div className={styles.contents}>
                <div className={styles.header}>
                    <BackButton onClick={goBack}/>
                    <h3 className={styles.title}>Корзина</h3>
                </div>
                <CartItemsList items={items} addItem={addItem} updateItemQuantity={updateItemQuantity} removeItem={removeItem} isEmpty={isEmpty}/>
            </div>
            {
                cartTotal ?
                    <div className={styles.stickToBottom}>
                        <div className={styles.summary}>
                            <div>
                                <span className={styles.label}>Итог: </span>
                                <span className={styles.value}>{cartTotal}₽</span>
                            </div>
                            <div>
                                <span className={styles.label}>Всего позиций: </span>
                                {/*<span className={styles.value}>{items.map(item => item.quantity).reduce((partialSum, a) => partialSum + parseInt(a), 0)}</span>*/}
                                <span className={styles.value}>{totalItems}</span>
                            </div>
                        </div>
                    </div>
                    :
                    null
            }
            <MainButton text={"Оформить заказ"} onClick={() => navigate('/order')} disable={isEmpty}/>
        </>
    )
}