import './App.module.css';

import {store} from "./utils/store";
import {Root} from "./components/common/Root/Root";
import {CartProvider} from "react-use-cart";

export const App = () => {
  return (
      <>
          <CartProvider>
              <Root store={store}/>
          </CartProvider>
      </>
  );
}
