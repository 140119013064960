import {
    CATALOG_CATEGORIES_LOADING, CATALOG_CATEGORIES_LOADING_FAIL,
    CATALOG_CATEGORIES_LOADING_SUCCESS,
    CATALOG_CATEGORY_CHANGED
} from "../../types/Catalog/Categories/types";
import {api} from "../../../utils/api";
import {loadCatalogProducts} from "../Products/actions";

export const loadCatalogCategories = () => dispatch => {
    dispatch({
        type: CATALOG_CATEGORIES_LOADING
    })

    api
        .get('catalog/categories')
        .then(response => {
            dispatch({
                type: CATALOG_CATEGORIES_LOADING_SUCCESS,
                payload: response.data
            })
        })
        .catch(error => {
            dispatch({
                type: CATALOG_CATEGORIES_LOADING_FAIL,
                payload: error.response.data
            })
        })
}

export const setActiveCategory = (id) => dispatch => {
    dispatch({
        type: CATALOG_CATEGORY_CHANGED,
        payload: id
    })
}
