import {CART_LOADING, CART_LOADING_FAIL, CART_LOADING_SUCCESS} from "../../actions/types/Cart/types";

const initialState = {
    loading: false,
    items: [],
    errors: []
}

export const cartReducer = (state = initialState, action) => {
    switch (action.type) {
        case CART_LOADING:
            return {
                ...state,
                loading: true,
                errors: []
            }
        case CART_LOADING_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.payload,
                errors: []
            }
        case CART_LOADING_FAIL:
            return {
                ...state,
                loading: false,
                errors: action.payload
            }
        default:
            return state
    }
}
