import {PRODUCT_LOADING, PRODUCT_LOADING_FAIL, PRODUCT_LOADING_SUCCESS} from "../types/Product/types";
import {api} from "../../utils/api";

export const loadProduct = (id) => dispatch => {
    dispatch({
        type: PRODUCT_LOADING
    })

    api
        .get(`/catalog/products?id=${id}`)
        .then(response => {
            dispatch({
                type: PRODUCT_LOADING_SUCCESS,
                payload: response.data
            })
        })
        .catch(error => {
            dispatch({
                type: PRODUCT_LOADING_FAIL,
                payload: error.response.data
            })
        })
}