import {CATALOG_PRODUCTS_LOADING, CATALOG_PRODUCTS_LOADING_FAIL, CATALOG_PRODUCTS_LOADING_SUCCESS} from "../../types/Catalog/Products/types";
import {api} from "../../../utils/api";

export const loadCatalogProducts = (category = undefined) => dispatch => {
    dispatch({
        type: CATALOG_PRODUCTS_LOADING
    })

    let url = "catalog/products"

    if (typeof category == "number")
        url += `?category=${category}`

    api.get(url)
        .then(response => {
            console.log(response.data)
            dispatch({
                type: CATALOG_PRODUCTS_LOADING_SUCCESS,
                payload: response.data
            })
        })
        .catch(error => {
            dispatch({
                type: CATALOG_PRODUCTS_LOADING_FAIL,
                payload: error.response.data
            })
        })

}