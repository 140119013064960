import styles from "./styles.module.css"
import {useNavigate, useParams} from "react-router-dom";
import {BackButton} from "@vkruglikov/react-telegram-web-app";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {loadProduct} from "../../actions/Product/actions";
import {ClipLoader} from "react-spinners";
import {setActiveCategory} from "../../actions/Catalog/Categories/actions";
import {useCart} from "react-use-cart";

export const ProductPage = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {id} = useParams()

    const _id = parseInt(id)

    const {
        updateItemQuantity,
        addItem,
        updateItem,
        getItem,
        items,
        totalItems,
        inCart
    } = useCart();

    const currentItem = getItem(_id)

    console.log(currentItem)

    useEffect(() => {
        dispatch(loadProduct(id))
    }, [dispatch, id])

    const product = useSelector(state => state.product)

    useEffect(() => {
        updateItem(product.id, {...product.data})
    }, [product])

    const goBack = () => {
        if (window.history.state && window.history.state.idx > 0) {
            navigate(-1);
        }
    }

    const goToCategory = () => {
        dispatch(setActiveCategory(category.id))
        navigate('/')
    }

    if (product.loading || product.data === null) {
        const color = document.documentElement.style.getPropertyValue('--tg-theme-button-color')

        return <div className={styles.loading}><ClipLoader color={color}/></div>
    }

    const {name, description, price, image, category} = product.data

    return (
        <>
            <div className={styles.contents}>
                <img src={image} alt={name}/>
                <div className={styles.info}>
                    <h2 className={styles.title}>{name}</h2>
                    <h3 className={styles.price}>{price}₽</h3>
                    <h3 className={styles.description_label}>О товаре</h3>
                    <p className={styles.description}>{description}</p>
                    <span className={styles.link} onClick={goToCategory}>{category.name}</span>
                </div>
            </div>
            <div className={styles.actions_container}>
                <div className={styles.wrapper}>
                    {
                        currentItem ?
                            <div className={styles.actions}>
                                <button onClick={() => updateItemQuantity(_id, currentItem.quantity - 1)}>-</button>
                                <input className={styles.label} value={parseInt(currentItem.quantity)}
                                       onChange={(event) => updateItemQuantity(_id, parseInt(event.target.value))}/>
                                <button onClick={() => updateItemQuantity(_id, currentItem.quantity + 1)}>+</button>
                            </div>
                            :
                            <button className={styles.addToCartButton} onClick={() => addItem(product.data, 1)}>В
                                корзину</button>
                    }
                </div>
            </div>
            <BackButton onClick={goBack}/>
        </>
    )
}