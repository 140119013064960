import styles from "./styles.module.css"
import {useDispatch} from "react-redux";
import {setActiveCategory} from "../../actions/Catalog/Categories/actions";
import {useNavigate} from "react-router-dom";

export const ProductCard = ({id, name, image, image_alt = name, price, category, addToCart, removeFromCart, quantity}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const setCategory = () => {
        dispatch(setActiveCategory(category.id))
    }

    const goToProductPage = () => {
        navigate(`product/${id}`)
    }

    return (
        <div className={styles.card}>
            <img src={image} alt={image_alt} onClick={goToProductPage}/>
            <p className={styles.title}>{name}</p>
            <div className={styles.meta}>
                <span className={styles.link} onClick={setCategory}>{category.name}</span>
                <p className={styles.price}>{price}₽</p>
            </div>
            {
                quantity ?
                    <div className={styles.actions}>
                        <button onClick={removeFromCart}>-</button>
                        <span className={styles.label}>{quantity}</span>
                        <button onClick={addToCart}>+</button>
                    </div>
                    :
                    <button className={styles.addToCartButton} onClick={addToCart}>В корзину</button>
            }

        </div>
    )
}